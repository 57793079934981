import { Box, Card, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Billing, BillingStatus, Quote, QuoteStatus, LegalDocument } from 'src/core/project/domain';
import Button from '../Button';
import EyeIcon from 'src/ui/atoms/icons/EyeIcon';
import QuoteStatusTag from './QuoteStatusTag';
import BillingStatusTag from './BillingStatusTag';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import formatDate from 'src/utils/formatDate';
import downloadFile from 'src/utils/downloadFile';
import { BackendDataSourceContext } from 'src/ui/contexts/backend-data-source-context';
import { getUserLang } from 'src/ui/i18n';

function DocumentSection({
    item,
    type,
}: {
    item: Quote | Billing | LegalDocument;
    type: 'billing' | 'quote' | 'legal-document';
}) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { projectState } = useContext(ProjectsContext)!;
    const dataSourceBackend = useContext(BackendDataSourceContext)!;

    const onClick = () => {
        const project = projectState.project;
        if (type && item.id) {
            authenticatedCall(async (accessToken) => {
                downloadFile(project, type + '-' + item.id, item.reference, dataSourceBackend, accessToken);
            });
        }
    };

    return (
        <Stack>
            <Typography color="grey" level="title-sm">
                {t(`${type}.file`)}
            </Typography>
            <Button
                label={t(`${type}.see_file`)}
                color="grey"
                variant="soft"
                startDecorator={
                    <Box>
                        <EyeIcon />
                    </Box>
                }
                onClick={onClick}
            />
        </Stack>
    );
}

export function QuoteCards({ quotes }: { quotes: Array<Quote> }) {
    useTranslation();

    const statusOrder: QuoteStatus[] = ['signed', 'validated', 'review_pending', 'new'];

    // Sort quotes status then date by default
    const sortedQuotes = quotes.sort((a, b) => {
        const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        if (statusComparison !== 0) {
            return statusComparison;
        }
        // If statuses are the same, sort by date
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    return (
        <>
            {sortedQuotes.map((quote, index) => (
                <AccountingCard
                    item={quote}
                    type="quote"
                    index={index + 1}
                    total={sortedQuotes.length}
                    key={`quote-${quote.id}`}
                />
            ))}
        </>
    );
}

export function LegalDocumentCards({ legalDocuments }: { legalDocuments: Array<LegalDocument> }) {
    useTranslation();
    return (
        <>
            {legalDocuments.map((legalDocument, index) => (
                <AccountingCard
                    item={legalDocument}
                    type="legal-document"
                    index={index + 1}
                    total={legalDocuments.length || 0}
                    key={`legal-document-${legalDocument.id}`}
                />
            ))}
        </>
    );
}

export default function AccountingCard({
    item,
    type,
    index,
    total,
    small,
}: {
    item: Quote | Billing | LegalDocument;
    type: 'billing' | 'quote' | 'legal-document';
    index: number;
    total?: number;
    small?: boolean;
}) {
    const { t } = useTranslation();
    const { projectState } = useContext(ProjectsContext)!;

    return (
        <Card variant={small ? 'outlined' : 'plain'} sx={{ margin: 1 }}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography level="h4" data-test-id={`${type}-reference-${index}`} data-test-value={item.reference}>
                    {t(`${type}.title`, { reference: item.reference, index, total })}
                </Typography>
                {type === 'quote' && <QuoteStatusTag status={item.status as QuoteStatus} />}
                {type === 'billing' && <BillingStatusTag status={item.status as BillingStatus} />}
            </Stack>
            {item.amount && (
                <Stack>
                    <Typography color="grey" level="title-sm">
                        {t(`${type}.amount_short`)}
                    </Typography>
                    <Typography level="title-sm" data-test-id={`${type}-amount-${index}`}>
                        {item.amount}
                    </Typography>
                </Stack>
            )}
            {item.date && (
                <Stack>
                    <Typography color="grey" level="title-sm">
                        {t(`${type}.date`)}
                    </Typography>
                    <Typography level="title-sm" data-test-id={`${type}-date-${index}`}>
                        {formatDate(item.date, false, false, getUserLang(), projectState.project?.address.countryCode)}
                    </Typography>
                </Stack>
            )}
            {!small && <DocumentSection item={item} type={type} />}
        </Card>
    );
}
