import { Card, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/ui/theme';

export default function LegalDocumentStatusCard({ status, onClick }: { status: string; onClick: () => void }) {
    const { t } = useTranslation();
    let titleCard;
    let subtitleCard;
    const color = useMemo(() => {
        switch (status) {
            case 'waiting_for_legal_documents_upload':
                return theme.palette.warning[100];
            default:
                return '';
        }
    }, [status]);

    const borderColor = useMemo(() => {
        switch (status) {
            case 'waiting_for_legal_documents_upload':
                return theme.palette.warning[500];
            default:
                return '';
        }
    }, [status]);

    if (status === 'waiting_for_legal_documents_upload') {
        titleCard = t(`legal-document.waiting-for-legal-documents-status-card.title`);
        subtitleCard = t(`legal-document.waiting-for-legal-documents-status-card.subtext`);
    } else {
        titleCard = t(`legal-document.status-card.title`);
        subtitleCard = t(`legal-document.status-card.subtext`);
    }

    return (
        <Card
            variant={status === 'waiting_for_legal_documents_upload' ? 'outlined' : 'plain'}
            sx={{
                backgroundColor: color,
                borderColor,
            }}
            color="warning"
            data-test-id={`LegalDocument-status-${status}`}
        >
            <Typography level="h4">{titleCard}</Typography>
            <Typography>{subtitleCard}</Typography>
            {
                // status === 'waiting_for_new_LegalDocument' && (
                // <Button label={t('LegalDocument.rejected.action')} color="neutral" onClick={onClick} />
                //)
            }
        </Card>
    );
}
