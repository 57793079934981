import { Card, IconButton, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import DarkCloseIcon from '../../atoms/icons/DarkCloseIcon';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import FileInput from '../forms/FileInput';

export interface LegalDocumentFormItem {
    reference: string;
    file?: string;
}

export default function LegalDocumentForm({ projectId, callback }: { projectId: string; callback?: () => void }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;

    const [LegalDocumentsToCreate, setLegalDocumentsToCreate] = useState<Array<LegalDocumentFormItem>>([
        { reference: '' },
    ]);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<(File | null)[]>([]);

    const setValue = (value: unknown, index: number, key: string) => {
        const newLegalDocument = { ...LegalDocumentsToCreate[index], [key]: value };
        setLegalDocumentsToCreate([
            ...LegalDocumentsToCreate.slice(0, index),
            newLegalDocument,
            ...LegalDocumentsToCreate.slice(index + 1),
        ]);
    };

    const isStateValid = useMemo(
        () => !LegalDocumentsToCreate.some((LegalDocument) => !LegalDocument.file || !LegalDocument.reference),
        [LegalDocumentsToCreate],
    );

    const submit = async () => {
        setUploading(true);
        try {
            await authenticatedCall(async (accessToken) =>
                dispatch(
                    projectActions.createLegalDocuments(
                        accessToken,
                        projectId,
                        LegalDocumentsToCreate as Array<{ reference: string; file: string }>,
                    ),
                ),
            );
            if (callback) {
                callback();
            }
            setLegalDocumentsToCreate([{ reference: '' }]);
            setFiles(files.map(() => null));
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setUploading(false);
        }
    };

    const convertAndSaveFile = (file: File | null, callback: (f: string | null) => void) => {
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                callback(reader.result as string);
            };
            reader.onerror = function (error) {
                console.error('Error: ', error);
                return null;
            };
        } else {
            callback(null);
        }
    };

    return LegalDocumentsToCreate.map((_, index) => (
        <Card variant="plain" key={`LegalDocument-${index}`}>
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h4" alignSelf="center">
                    {t('legal-document.new_legal_document', { index: index + 1 })}
                </Typography>
                {LegalDocumentsToCreate.length !== 1 && (
                    <IconButton
                        color="neutral"
                        onClick={() => {
                            setLegalDocumentsToCreate(LegalDocumentsToCreate.splice(index, 1));
                        }}
                    >
                        <DarkCloseIcon />
                    </IconButton>
                )}
            </Stack>
            <FloatingLabelInput
                label={t('legal-document.reference')}
                value={LegalDocumentsToCreate[index].reference}
                onChange={(e) => setValue(e.target.value, index, 'reference')}
                data-test-id={`reference-input-${index}`}
            />
            <FileInput
                onChange={(file) => {
                    convertAndSaveFile(file, (f) => setValue(f, index, 'file'));
                    setFiles((files) => {
                        const newFiles = [...files];
                        newFiles[index] = file;
                        return newFiles;
                    });
                }}
                dataTestId={`file-input-${index}`}
                file={files[index] || null}
                setFile={(file) => {
                    setFiles((prevFiles) => {
                        const newFiles = [...prevFiles];
                        newFiles[index] = file;
                        return newFiles;
                    });
                }}
            />
            {index + 1 === LegalDocumentsToCreate.length && (
                <>
                    <Button
                        onClick={submit}
                        disabled={!isStateValid}
                        loading={uploading}
                        label={t('legal-document.submit', { number: LegalDocumentsToCreate.length })}
                        data-test-id="submit"
                    />
                    <Button
                        onClick={() => {
                            setLegalDocumentsToCreate([...LegalDocumentsToCreate, { reference: '' }]);
                            window.scrollTo(0, document.body.scrollHeight);
                        }}
                        disabled={uploading}
                        variant="outlined"
                        color="neutral"
                        label={t('legal-document.additional_document')}
                        data-test-id="additionnal-legal-document"
                    />
                </>
            )}
        </Card>
    ));
}
