import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAppAuth } from '../../contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import { Billing, Project } from 'src/core/project/domain';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import BillingForm from './BillingForm';
import AliceCarousel from 'react-alice-carousel';
import AccountingCard from './AccountingCard';
import { PurchaseOrderCards } from './PurchaseOrderCard';

export function Billings({ project }: { project: Project }) {
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        if (project && project.status === 'waiting_for_billing_upload') {
            setShowForm(true);
        }
        if (project && project.status !== 'waiting_for_billing_upload') {
            setShowForm(false);
        }
    }, [project]);

    const currentBilling = useMemo(
        () => (project?.billings ?? []).find((billing) => billing.status !== 'rejected'),
        [project.billings],
    );
    const rejectedBillings: Array<Billing> = useMemo(
        () => (project?.billings ?? []).filter((billing) => billing.status === 'rejected') || [],
        [project.billings],
    );

    return (
        <>
            <PurchaseOrderCards project={project} />
            {showForm && (
                <BillingForm
                    projectId={project.id}
                    callback={() =>
                        authenticatedCall(async (accessToken) =>
                            dispatch(projectActions.loadOne(accessToken, project.id)),
                        )
                    }
                />
            )}
            {currentBilling && (
                <AccountingCard item={currentBilling} type="billing" index={1} key={`billing-${currentBilling.id}`} />
            )}
            {rejectedBillings !== null && rejectedBillings.length !== 0 && (
                <AliceCarousel
                    disableButtonsControls={true}
                    responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                    controlsStrategy="responsive"
                    items={rejectedBillings.map((billing, index) => (
                        <AccountingCard
                            item={billing}
                            type="billing"
                            index={index + 1}
                            key={`billing-${billing.id}`}
                            small
                        />
                    ))}
                />
            )}
        </>
    );
}
