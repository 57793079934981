import { ActionExtractor, createAction } from '../action';
import { ProjectDataSource } from './data-source';
import { Delivery, Project } from './domain';
import { AppUser } from 'src/ui/contexts/auth-context';
import { DocumentDto } from 'src/ui/molecules/documents/DocumentForm';

// Actions
export const projectActions = {
    loadAll: (accessToken: string) => createAction('loadAll', { accessToken }),
    loadOne: (accessToken: string, id: string) => createAction('loadOne', { accessToken, id }),
    accept: (accessToken: string, id: string) => createAction('accept', { accessToken, id }),
    refuse: (accessToken: string, id: string) => createAction('refuse', { accessToken, id }),
    updateStatus: (accessToken: string, id: string, status: string) =>
        createAction('updateStatus', { accessToken, id, status }),
    updateSurvey: (accessToken: string, id: string, startDate: Date, endDate: Date, status?: string) =>
        createAction('updateSurvey', { accessToken, id, startDate, endDate, status }),
    createQuotes: (
        accessToken: string,
        id: string,
        quotes: Array<{ reference: string; amount: number; description: string; file: string }>,
    ) => createAction('createQuotes', { accessToken, id, quotes }),
    createLegalDocuments: (
        accessToken: string,
        id: string,
        legalDocuments: Array<{ reference: string; file: string }>,
    ) => createAction('createLegalDocuments', { accessToken, id, legalDocuments }),
    createBilling: (accessToken: string, id: string, reference: string, amount: number, file: string) =>
        createAction('createBilling', { accessToken, id, reference, amount, file }),
    createSurvey: (accessToken: string, id: string, file: string) =>
        createAction('createSurvey', { accessToken, id, file }),
    updateInstallation: (accessToken: string, id: string, startDate: Date, endDate: Date, status?: string) =>
        createAction('updateInstallation', { accessToken, id, startDate, endDate, status }),
    updateDelivery: (accessToken: string, delivery: Delivery) =>
        createAction('updateDelivery', { accessToken, delivery }),
    updateDeliveryStatus: (accessToken: string, delivery: Delivery) =>
        createAction('updateDeliveryStatus', { accessToken, delivery }),
    setCurrentPage: (page: number) => createAction('setCurrentPage', { page }),
    changeUserLanguage: (accessToken: string, lang: string) =>
        createAction('changeUserLanguage', { accessToken, lang }),
    retrieveFileByS3Key: (accessToken: string, projectId: string, s3Key: string) =>
        createAction('retrieveFileByS3Key', { accessToken, projectId, s3Key }),
    createDocuments: (accessToken: string, documents: Array<DocumentDto>) =>
        createAction('createDocuments', { accessToken, documents }),
};

export type ProjectAction = ActionExtractor<typeof projectActions>;

// State
export type ProjectState = {
    project: Project | null;
    user: AppUser | null;
    projects: Array<Project>;
    documents: { [id: string]: string | null };
    files: { [id: string]: string | null };
    error: unknown;
    page: number;
};
export const projectInitialState: ProjectState = {
    project: null,
    user: {},
    projects: [],
    documents: {},
    files: {},
    error: undefined,
    page: 1,
};

// Reducer
export type ProjectReducer = (state: ProjectState, action: ProjectAction) => Promise<ProjectState>;
export const projectReducer =
    (projectDatasource: ProjectDataSource) =>
    async (state: ProjectState, action: ProjectAction): Promise<ProjectState> => {
        switch (action.type) {
            case 'loadAll': {
                return {
                    ...state,
                    projects: await projectDatasource.retrieveProjects(action.payload.accessToken),
                };
            }
            case 'loadOne': {
                return {
                    ...state,
                    project: await projectDatasource.retrieveProject(action.payload.accessToken, action.payload.id),
                };
            }
            case 'accept': {
                return {
                    ...state,
                    project: await projectDatasource.acceptProject(action.payload.accessToken, action.payload.id),
                };
            }
            case 'refuse': {
                return {
                    ...state,
                    project: await projectDatasource.refuseProject(action.payload.accessToken, action.payload.id),
                };
            }
            case 'updateStatus': {
                return {
                    ...state,
                    project: await projectDatasource.updateStatus(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.status,
                    ),
                };
            }
            case 'updateSurvey': {
                return {
                    ...state,
                    project: await projectDatasource.updateSurvey(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.startDate,
                        action.payload.endDate,
                        action.payload.status,
                    ),
                };
            }
            case 'updateInstallation': {
                return {
                    ...state,
                    project: await projectDatasource.updateInstallation(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.startDate,
                        action.payload.endDate,
                        action.payload.status,
                    ),
                };
            }
            case 'updateDelivery': {
                let updatedDelivery: Delivery | null = await projectDatasource.updateDelivery(
                    action.payload.accessToken,
                    action.payload.delivery,
                );
                if (updatedDelivery != null && state.project) {
                    const deliveries = state.project.deliveries.map((delivery) =>
                        delivery.id === updatedDelivery?.id
                            ? { ...updatedDelivery, items: action.payload.delivery.items }
                            : delivery,
                    );

                    return {
                        ...state,
                        project: {
                            ...state.project,
                            deliveries: deliveries,
                        },
                    };
                }
                return {
                    ...state,
                };
            }
            case 'updateDeliveryStatus': {
                let updatedDelivery: Delivery | null = await projectDatasource.updateDeliveryStatus(
                    action.payload.accessToken,
                    action.payload.delivery,
                );
                if (updatedDelivery != null && state.project) {
                    const deliveries = state.project.deliveries.map((delivery) =>
                        delivery.id === updatedDelivery?.id
                            ? { ...updatedDelivery, items: action.payload.delivery.items }
                            : delivery,
                    );

                    return {
                        ...state,
                        project: {
                            ...state.project,
                            deliveries: deliveries,
                        },
                    };
                }
                return {
                    ...state,
                };
            }
            case 'createQuotes': {
                const newQuotes = [];

                // process quotes in sequence
                for (let quote of action.payload.quotes) {
                    const newQuote = await projectDatasource.createQuote(
                        action.payload.accessToken,
                        action.payload.id,
                        quote.reference,
                        quote.amount,
                        quote.description,
                        quote.file,
                    );
                    if (newQuote) {
                        newQuotes.push(newQuote);
                    }
                }

                if (newQuotes) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            quotes: state.project?.quotes!.concat(...newQuotes)!,
                        },
                    };
                }

                return state;
            }
            case 'createLegalDocuments': {
                const newLegalDocuments = [];

                // process legal documents in sequence
                for (let legalDocument of action.payload.legalDocuments) {
                    const newLegalDocument = await projectDatasource.createLegalDocument(
                        action.payload.accessToken,
                        action.payload.id,
                        legalDocument.reference,
                        legalDocument.file,
                    );
                    if (newLegalDocument) {
                        newLegalDocuments.push(newLegalDocument);
                    }
                }

                if (newLegalDocuments) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            legalDocuments: state.project?.legalDocuments!.concat(...newLegalDocuments)!,
                        },
                    };
                }

                return state;
            }
            case 'createBilling': {
                const newBilling = await projectDatasource.createBilling(
                    action.payload.accessToken,
                    action.payload.id,
                    action.payload.reference,
                    action.payload.amount,
                    action.payload.file,
                );
                if (newBilling) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            billings: [...state.project!.billings, newBilling],
                        },
                    };
                }

                return state;
            }
            case 'createSurvey': {
                const newSurvey = await projectDatasource.createSurvey(
                    action.payload.accessToken,
                    action.payload.id,
                    action.payload.file,
                );
                if (newSurvey) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            survey: newSurvey,
                        },
                    };
                }

                return state;
            }
            case 'setCurrentPage': {
                return {
                    ...state,
                    page: action.payload.page,
                };
            }
            case 'changeUserLanguage': {
                return {
                    ...state,
                    user: await projectDatasource.changeUserLanguage(action.payload.accessToken, action.payload.lang),
                };
            }

            case 'retrieveFileByS3Key': {
                return {
                    ...state,
                    files: {
                        ...state.files!,
                        [action.payload.s3Key]: await projectDatasource.retrieveFileByS3Key(
                            action.payload.accessToken,
                            action.payload.projectId,
                            action.payload.s3Key,
                        ),
                    },
                };
            }
            case 'createDocuments': {
                const newDocuments: any[] = [];

                // process files in sequence
                for (let document of action.payload.documents) {
                    const newFile = await projectDatasource.createDocument(action.payload.accessToken, document);
                    if (document) {
                        newDocuments.push(newFile);
                    }
                }

                if (newDocuments) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            documents: state.project?.documents!.concat(...newDocuments)!,
                        },
                    };
                }

                return state;
            }
        }
    };
