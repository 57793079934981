import { Card, IconButton, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import DarkCloseIcon from '../../atoms/icons/DarkCloseIcon';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import FileInput from '../forms/FileInput';

export interface QuoteFormItem {
    reference: string;
    amount: number;
    description: string;
    file?: string;
}

export default function QuoteForm({ projectId, callback }: { projectId: string; callback?: () => void }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;

    const [quotesToCreate, setQuotesToCreate] = useState<Array<QuoteFormItem>>([
        { reference: '', amount: 0, description: '' },
    ]);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<(File | null)[]>([]);

    const setValue = (value: unknown, index: number, key: string) => {
        const newQuote = { ...quotesToCreate[index], [key]: value };
        setQuotesToCreate([...quotesToCreate.slice(0, index), newQuote, ...quotesToCreate.slice(index + 1)]);
    };

    const isStateValid = useMemo(
        () => !quotesToCreate.some((quote) => !quote.file || !quote.reference),
        [quotesToCreate],
    );

    const submit = async () => {
        setUploading(true);
        try {
            await authenticatedCall(async (accessToken) =>
                dispatch(
                    projectActions.createQuotes(
                        accessToken,
                        projectId,
                        quotesToCreate as Array<{
                            reference: string;
                            amount: number;
                            description: string;
                            file: string;
                        }>,
                    ),
                ),
            );
            if (callback) {
                callback();
            }
            setQuotesToCreate([{ reference: '', amount: 0, description: '' }]);
            setFiles(files.map(() => null));
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setUploading(false);
        }
    };

    const convertAndSaveFile = (file: File | null, callback: (f: string | null) => void) => {
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                callback(reader.result as string);
            };
            reader.onerror = function (error) {
                console.error('Error: ', error);
                return null;
            };
        } else {
            callback(null);
        }
    };

    return quotesToCreate.map((_, index) => (
        <Card variant="plain" key={`quote-${index}`}>
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h4" alignSelf="center">
                    {t('quote.new_quote')}
                </Typography>
                {quotesToCreate.length !== 1 && (
                    <IconButton
                        color="neutral"
                        onClick={() => {
                            setQuotesToCreate((quotesToCreate) => quotesToCreate.filter((quote, i) => i !== index));
                        }}
                    >
                        <DarkCloseIcon />
                    </IconButton>
                )}
            </Stack>
            <FloatingLabelInput
                label={t('quote.reference')}
                value={quotesToCreate[index].reference}
                onChange={(e) => setValue(e.target.value, index, 'reference')}
                data-test-id={`reference-input-${index}`}
            />
            <FloatingLabelInput
                label={t('quote.amount')}
                value={quotesToCreate[index].amount}
                type="number"
                onChange={(e) => setValue(e.target.value, index, 'amount')}
                data-test-id={`amount-input-${index}`}
            />
            <FloatingLabelInput
                label={t('quote.description')}
                value={quotesToCreate[index].description}
                onChange={(e) => setValue(e.target.value, index, 'description')}
                data-test-id={`reference-input-${index}`}
            />
            <FileInput
                onChange={(file) => {
                    convertAndSaveFile(file, (f) => setValue(f, index, 'file'));
                    setFiles((files) => {
                        const newFiles = [...files];
                        newFiles[index] = file;
                        return newFiles;
                    });
                }}
                dataTestId={`file-input-${index}`}
                file={files[index] || null}
                setFile={(file) => {
                    setFiles((prevFiles) => {
                        const newFiles = [...prevFiles];
                        newFiles[index] = file;
                        return newFiles;
                    });
                }}
            />
            {index + 1 === quotesToCreate.length && (
                <>
                    <Button
                        onClick={submit}
                        disabled={!isStateValid}
                        loading={uploading}
                        label={t('quote.submit', { number: quotesToCreate.length })}
                        data-test-id="submit"
                    />
                    <Button
                        onClick={() => {
                            setQuotesToCreate([...quotesToCreate, { reference: '', amount: 0, description: '' }]);
                            window.scrollTo(0, document.body.scrollHeight);
                        }}
                        disabled={uploading}
                        variant="outlined"
                        color="neutral"
                        label={t('quote.additional_quote')}
                        data-test-id="additional-quote"
                    />
                </>
            )}
        </Card>
    ));
}
