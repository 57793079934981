import React, { useContext, useEffect, useState } from 'react';
import { ProjectsContext } from '../../contexts/project-context';
import { useAppAuth } from '../../contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import { LegalDocumentCards } from './AccountingCard';
import LegalDocumentStatusCard from './LegalDocumentStatusCard';
import LegalDocumentForm from './LegalDocumentForm';
import { Project } from 'src/core/project/domain';

export function LegalDocuments({ project, showFormAtStart }: { project: Project; showFormAtStart?: boolean }) {
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        setShowForm(true);
    }, [project]);

    useEffect(() => {
        setShowForm(true);
    }, [project.status, showFormAtStart]);

    console.log('showForm', showForm);

    return (
        <>
            <>
                {<LegalDocumentStatusCard status={project.status} onClick={() => setShowForm(true)} />}
                <LegalDocumentCards legalDocuments={project.legalDocuments} />
            </>

            <LegalDocumentForm
                projectId={project.id}
                callback={() =>
                    authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, project.id)))
                }
            />
        </>
    );
}
