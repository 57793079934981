import { Card, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import FileInput from '../forms/FileInput';

export interface BillingFormItem {
    reference: string;
    amount: number;
    file?: string;
}

export default function BillingForm({ projectId, callback }: { projectId: string; callback?: () => void }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;

    const [billingToCreate, setBillingToCreate] = useState<BillingFormItem>({ reference: '', amount: 0 });
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const submit = async () => {
        setUploading(true);
        try {
            await authenticatedCall(async (accessToken) =>
                dispatch(
                    projectActions.createBilling(
                        accessToken,
                        projectId,
                        billingToCreate.reference,
                        billingToCreate.amount,
                        billingToCreate.file!,
                    ),
                ),
            );
            if (callback) {
                callback();
            }
            setFile(file);
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setUploading(false);
        }
    };

    const convertAndSaveFile = (file: File | null, callback: (f: string | null) => void) => {
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                callback(reader.result as string);
            };
            reader.onerror = function (error) {
                console.error('Error: ', error);
                return null;
            };
        } else {
            callback(null);
        }
    };

    return (
        <Card variant="plain">
            <Typography level="h4" alignSelf="center">
                {t('billing.new_billing')}
            </Typography>
            <FloatingLabelInput
                label={t('billing.reference')}
                value={billingToCreate.reference}
                onChange={(e) => setBillingToCreate({ ...billingToCreate, reference: e.target.value })}
                data-test-id="reference-input"
            />
            <FloatingLabelInput
                label={t('billing.amount')}
                value={billingToCreate.amount}
                type="number"
                onChange={(e) => setBillingToCreate({ ...billingToCreate, amount: parseFloat(e.target.value) })}
                data-test-id="amount-input"
            />
            <FileInput
                onChange={(file) => {
                    convertAndSaveFile(file, (f) => {
                        if (file) {
                            setBillingToCreate({ ...billingToCreate, file: f! });
                        }
                    });
                    setFile(file);
                }}
                dataTestId={`file-input`}
                file={file || null}
                setFile={(file) => {
                    setFile(file);
                }}
            />
            <Button
                onClick={submit}
                disabled={!billingToCreate.file || !billingToCreate.reference}
                loading={uploading}
                label={t('billing.submit')}
                data-test-id="submit"
            />
        </Card>
    );
}
