import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import Loader from '../molecules/Loader';
import { Quotes } from '../molecules/accounting/Quotes';
import { Billings } from '../molecules/accounting/Billings';
import BackButton from '../molecules/BackButton';
import { Stack } from '@mui/joy';

export function ProjectAccountingPage() {
    const { id } = useParams();
    const { authenticatedCall } = useAppAuth();
    const { state } = useLocation();
    const { projectState, dispatch } = useContext(ProjectsContext)!;

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="accounting-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label="accounting.back" />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <div data-test-id="accounting-page" />
                        {['waiting_for_billing_upload', 'waiting_for_billing_validation', 'project_completed'].includes(
                            projectState.project.status,
                        ) ? (
                            <>
                                <Billings project={projectState.project} />
                                <Quotes
                                    project={projectState.project}
                                    showFormAtStart={state && state.showForm}
                                    showFormAndStatus={false}
                                />
                            </>
                        ) : (
                            <Quotes
                                project={projectState.project}
                                showFormAtStart={state && state.showForm}
                                showFormAndStatus={true}
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
