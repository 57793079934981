import { Box, Button, List, ListDivider, ListItem, Stack, SvgIcon, Typography } from '@mui/joy';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectsContext } from '../contexts/project-context';
import ProjectOverview from '../molecules/projects/ProjectOverview';
import { projectActions } from 'src/core/project/service';
import Pagination from '../molecules/Pagination';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import { theme } from '../theme';
import FilterIcon from '../atoms/icons/FilterIcon';
import RightSecondaryArrowIcon from '../atoms/icons/RightSecondaryArrowIcon';
import { Project, User } from 'src/core/project/domain';
import getStatusKey from 'src/utils/getStatusKey';

export function Projects() {
    const pageSize = 10;
    const { authenticatedCall } = useAppAuth();
    const { t } = useTranslation();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const [loading, setLoading] = useState(true);
    const [{ fromIdx, toIdx }, setPage] = useState({
        fromIdx: (projectState.page - 1) * pageSize,
        toIdx: projectState.page * pageSize - 1,
    });
    const [hideSidebar, setHideSidebar] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [projectsToDisplay, setProjectsToDisplay] = useState<Project[]>([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && projectState.projects.length > 0) {
            setProjectsToDisplay(projectState.projects);
            setInitialized(true);
        }
    }, [projectState.projects, initialized]);

    const filters = [
        'new',
        'pending_survey_planification',
        'pending_technical_visit',
        'pending_technical_visit_documents',
        'pending_quote',
        'waiting_chargeguru',
        'negotiation',
        'pending_installation',
        'pending_installation_date',
        'pending_delivery',
        'pending_installation_documents',
        'processing_invoice',
        'project_finished',
        'project_lost',
    ];

    interface FilteredProjects {
        new: Project[];
        pending_survey_planification: Project[];
        pending_technical_visit: Project[];
        pending_technical_visit_documents: Project[];
        pending_quote: Project[];
        waiting_chargeguru: Project[];
        negotiation: Project[];
        pending_installation: Project[];
        pending_installation_date: Project[];
        pending_delivery: Project[];
        pending_installation_documents: Project[];
        processing_invoice: Project[];
        project_finished: Project[];
        project_lost: Project[];
        else: Project[];
    }

    const userList: { user: User; projects: FilteredProjects }[] = projectState.projects.reduce(
        (acc: { user: User; projects: FilteredProjects }[], project) => {
            const existingUser = acc.find((u) => u.user.authId === project.user.authId);
            let projects: FilteredProjects = {
                new: [],
                pending_survey_planification: [],
                pending_technical_visit: [],
                pending_technical_visit_documents: [],
                pending_quote: [],
                waiting_chargeguru: [],
                negotiation: [],
                pending_installation: [],
                pending_installation_date: [],
                pending_delivery: [],
                pending_installation_documents: [],
                processing_invoice: [],
                project_finished: [],
                project_lost: [],
                else: [],
            };

            if (['project_proposed'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.new.push(project);
                } else {
                    projects.new.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['project_accepted'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.pending_survey_planification.push(project);
                } else {
                    projects.pending_survey_planification.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['survey_planned'].includes(project.status)) {
                if (project.survey?.endDate && new Date(project.survey?.endDate!).getTime() < Date.now()) {
                    if (existingUser) {
                        existingUser.projects.pending_technical_visit_documents.push(project);
                    } else {
                        projects.pending_technical_visit_documents.push(project);
                        acc.push({
                            user: project.user,
                            projects: projects,
                        });
                    }
                } else {
                    if (existingUser) {
                        existingUser.projects.pending_technical_visit.push(project);
                    } else {
                        projects.pending_technical_visit.push(project);
                        acc.push({
                            user: project.user,
                            projects: projects,
                        });
                    }
                }
            } else if (['survey_conducted'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.pending_quote.push(project);
                } else {
                    projects.pending_quote.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['quote_proposed', 'waiting_for_new_quote', 'quote_validated'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.negotiation.push(project);
                } else {
                    projects.negotiation.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['quote_signed', 'project_standby'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.waiting_chargeguru.push(project);
                } else {
                    projects.waiting_chargeguru.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['installation_planned'].includes(getStatusKey(project))) {
                if (existingUser) {
                    existingUser.projects.pending_installation.push(project);
                } else {
                    projects.pending_installation.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['installation_to_plan'].includes(getStatusKey(project))) {
                if (existingUser) {
                    existingUser.projects.pending_installation_date.push(project);
                } else {
                    projects.pending_installation_date.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['shipping_in_progress'].includes(getStatusKey(project))) {
                if (existingUser) {
                    existingUser.projects.pending_delivery.push(project);
                } else {
                    projects.pending_delivery.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (
                [
                    'waiting_for_sign_off_sheet_signature',
                    'waiting_for_legal_documents_upload',
                    'waiting_for_billing_upload',
                ].includes(project.status)
            ) {
                if (existingUser) {
                    existingUser.projects.pending_installation_documents.push(project);
                } else {
                    projects.pending_installation_documents.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['waiting_for_billing_validation'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.processing_invoice.push(project);
                } else {
                    projects.processing_invoice.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (
                ['project_completed'].includes(project.status) &&
                project.billings.every((billing) => ['paid'].includes(billing.status))
            ) {
                if (existingUser) {
                    existingUser.projects.project_finished.push(project);
                } else {
                    projects.project_finished.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['project_lost'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.project_lost.push(project);
                } else {
                    projects.project_lost.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else {
                if (existingUser) {
                    existingUser.projects.else.push(project);
                } else {
                    projects.else.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            }
            return acc;
        },
        [],
    );

    useEffect(() => {
        authenticatedCall(async (accessToken) => dispatch(projectActions.loadAll(accessToken))).then(() => {
            setLoading(false);
        });
    }, [dispatch, authenticatedCall]);

    const onPageChange = useCallback(
        (fromIdx: number, toIdx: number) => {
            setPage({ fromIdx, toIdx });
        },
        [setPage],
    );

    const toggleSidebar = () => {
        setHideSidebar(!hideSidebar);
    };

    console.log(selectedFilters);

    const handleFilterChange = (filter: string) => {
        // status filtering
        if (filters.includes(filter)) {
            if (!selectedFilters.includes(filter)) {
                setSelectedFilters([...selectedFilters, filter]); //add the filter to selectedFilters
                if (selectedUsers.length === 0) {
                    // pink
                    let projectsList: Project[] = [];
                    userList.forEach((item) => {
                        projectsList = projectsList.concat(item.projects[filter as keyof FilteredProjects]);
                        for (let key of selectedFilters) {
                            projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                        }
                    });
                    setProjectsToDisplay(projectsList);
                } else {
                    //blue
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers].includes(item.user.id))
                        .forEach((item) => {
                            projectsList = projectsList.concat(item.projects[filter as keyof FilteredProjects]);
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                    setProjectsToDisplay(projectsList);
                }
            } else {
                setSelectedFilters(selectedFilters.filter((u) => u !== filter)); //remove the filter from selectFilters
                if (selectedUsers.length === 0) {
                    if (selectedFilters.length === 1) {
                        // plain
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // pink
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            for (let key of selectedFilters) {
                                if (key === filter) {
                                    continue;
                                }
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                        setProjectsToDisplay(projectsList);
                    }
                } else {
                    if (selectedFilters.length === 1) {
                        // grey
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id))
                            .forEach((item) => {
                                projectsList = projectsList.concat(Object.values(item.projects).flat());
                            });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // blue
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id))
                            .forEach((item) => {
                                for (let key of selectedFilters) {
                                    if (key === filter) {
                                        continue;
                                    }
                                    projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                                }
                            });
                        setProjectsToDisplay(projectsList);
                    }
                }
            }
        }
        // user filtering
        else {
            if (!selectedUsers.includes(filter)) {
                setSelectedUsers([...selectedUsers, filter]); //add the user to selectedUsers
                if (selectedFilters.length === 0) {
                    // grey
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers, filter].includes(item.user.id))
                        .forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                    setProjectsToDisplay(projectsList);
                } else {
                    //blue
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers, filter].includes(item.user.id))
                        .forEach((item) => {
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                    setProjectsToDisplay(projectsList);
                }
            } else {
                setSelectedUsers(selectedUsers.filter((u) => u !== filter)); //remove the user from selectedUsers
                if (selectedFilters.length === 0) {
                    if (selectedUsers.length === 1) {
                        // plain
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // grey
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id) && item.user.id !== filter)
                            .forEach((item) => {
                                projectsList = projectsList.concat(Object.values(item.projects).flat());
                            });
                        setProjectsToDisplay(projectsList);
                    }
                } else {
                    if (selectedUsers.length === 1) {
                        // pink
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // blue
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id) && item.user.id !== filter)
                            .forEach((item) => {
                                for (let key of selectedFilters) {
                                    projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                                }
                            });
                        setProjectsToDisplay(projectsList);
                    }
                }
            }
        }
    };

    function Sidebar() {
        return (
            <div
                style={{
                    width: '320px',
                    backgroundColor: '#f0f0f0',
                    height: '100%',
                    position: 'fixed',
                    top: 0,
                    right: 0,
                }}
            >
                <Stack direction="column" sx={{ justifyContent: 'space-between', paddingX: '20px', paddingY: '35px' }}>
                    <Button
                        color="grey"
                        variant="plain"
                        sx={{ paddingLeft: '0', paddingRight: '0', width: 'fit-content' }}
                        onClick={toggleSidebar}
                    >
                        <Stack direction="row" alignItems="center">
                            <SvgIcon
                                alignmentBaseline="central"
                                sx={{ marginRight: '8px', width: '13px', height: '11px' }}
                                viewBox="0 0 13 11"
                            >
                                <RightSecondaryArrowIcon />
                            </SvgIcon>
                            <Typography level="h3" sx={{ color: 'grey.500' }}>
                                {t('filter.close')}
                            </Typography>
                        </Stack>
                    </Button>
                    {userList.length > 1 ? (
                        <>
                            <Typography fontWeight={'bold'}>{t('filter.user', { Number: userList.length })}</Typography>

                            {userList.map((item, idx) => (
                                <div key={item.user.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleFilterChange(item.user.id)}
                                            checked={selectedUsers.includes(item.user.id)}
                                        />
                                        {item.user.email}
                                        {/* {`${item.user.email} (${Object.values(item.projects).reduce(
                                            (sum, list) => sum + list.length,
                                            0,
                                        )})`} */}
                                    </label>
                                </div>
                            ))}
                        </>
                    ) : null}
                    <Typography fontWeight={'bold'}>
                        {t('filter.status.all', { Number: projectState.projects.length })}
                    </Typography>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('new')}
                            checked={selectedFilters.includes('new')}
                        />
                        {t('filter.status.new', {
                            Number: userList
                                .map((item) => item.projects.new.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_survey_planification')}
                            checked={selectedFilters.includes('pending_survey_planification')}
                        />
                        {t('filter.status.pending_survey_planification', {
                            Number: userList
                                .map((item) => item.projects.pending_survey_planification.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_technical_visit')}
                            checked={selectedFilters.includes('pending_technical_visit')}
                        />
                        {t('filter.status.pending_technical_visit', {
                            Number: userList
                                .map((item) => item.projects.pending_technical_visit.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_technical_visit_documents')}
                            checked={selectedFilters.includes('pending_technical_visit_documents')}
                        />
                        {t('filter.status.pending_technical_visit_documents', {
                            Number: userList
                                .map((item) => item.projects.pending_technical_visit_documents.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_quote')}
                            checked={selectedFilters.includes('pending_quote')}
                        />
                        {t('filter.status.pending_quote', {
                            Number: userList
                                .map((item) => item.projects.pending_quote.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('negotiation')}
                            checked={selectedFilters.includes('negotiation')}
                        />
                        {t('filter.status.negotiation', {
                            Number: userList
                                .map((item) => item.projects.negotiation.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('waiting_chargeguru')}
                            checked={selectedFilters.includes('waiting_chargeguru')}
                        />
                        {t('filter.status.waiting_chargeguru', {
                            Number: userList
                                .map((item) => item.projects.waiting_chargeguru.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_installation_date')}
                            checked={selectedFilters.includes('pending_installation_date')}
                        />
                        {t('filter.status.pending_installation_date', {
                            Number: userList
                                .map((item) => item.projects.pending_installation_date.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_installation')}
                            checked={selectedFilters.includes('pending_installation')}
                        />
                        {t('filter.status.pending_installation', {
                            Number: userList
                                .map((item) => item.projects.pending_installation.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_delivery')}
                            checked={selectedFilters.includes('pending_delivery')}
                        />
                        {t('filter.status.pending_delivery', {
                            Number: userList
                                .map((item) => item.projects.pending_delivery.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('pending_installation_documents')}
                            checked={selectedFilters.includes('pending_installation_documents')}
                        />
                        {t('filter.status.pending_installation_documents', {
                            Number: userList
                                .map((item) => item.projects.pending_installation_documents.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('processing_invoice')}
                            checked={selectedFilters.includes('processing_invoice')}
                        />
                        {t('filter.status.processing_invoice', {
                            Number: userList
                                .map((item) => item.projects.processing_invoice.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('project_finished')}
                            checked={selectedFilters.includes('project_finished')}
                        />
                        {t('filter.status.project_finished', {
                            Number: userList
                                .map((item) => item.projects.project_finished.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('project_lost')}
                            checked={selectedFilters.includes('project_lost')}
                        />
                        {t('filter.status.project_lost', {
                            Number: userList
                                .map((item) => item.projects.project_lost.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                </Stack>
            </div>
        );
    }

    if (projectState.projects.length === 0 && !loading) {
        return (
            <PageTemplate greyBackground>
                <Stack height="100vh" justifyContent="center" alignItems="center">
                    <Typography level="h3" textAlign={'center'} sx={{ color: theme.palette.secondary[500] }}>
                        {t('no_projects')}
                    </Typography>
                </Stack>
            </PageTemplate>
        );
    }

    return (
        <PageTemplate>
            <Stack sx={{ justifyContent: 'space-between', height: '100%', paddingX: '20px', paddingY: '40px' }}>
                <Box>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography level="h1" data-test-id="projects_page">
                            {t('your_projects')}
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                border: 'lightgrey',
                                width: 'fit-content',
                                background: 'lightgrey',
                                color: 'black',
                            }}
                            startDecorator={
                                <Box>
                                    <FilterIcon />
                                </Box>
                            }
                            onClick={toggleSidebar}
                        >
                            {t('filter.open')}
                        </Button>
                    </Stack>
                    <List>
                        {projectsToDisplay
                            .filter((_, idx) => idx >= fromIdx && idx <= toIdx)
                            .map((project, idx) => (
                                <div key={project.id}>
                                    <ListItem sx={{ paddingX: '0px', paddingY: '0px', marginY: '16px' }}>
                                        <Stack width="100%">
                                            <ProjectOverview project={project} />
                                        </Stack>
                                    </ListItem>
                                    {idx < projectsToDisplay.length - 1 ? <ListDivider inset="gutter" /> : ''}
                                </div>
                            ))}
                    </List>
                </Box>
                <Pagination
                    count={projectsToDisplay.length}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    fromId={fromIdx}
                    entity="project"
                />
            </Stack>
            {hideSidebar ? null : <Sidebar />}
        </PageTemplate>
    );
}
