import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import Loader from '../molecules/Loader';
import { LegalDocuments } from '../molecules/accounting/LegalDocuments';

import BackButton from '../molecules/BackButton';
import { Stack } from '@mui/joy';
import { t } from 'i18next';

export function UploadLegalDocumentsPage() {
    const { id } = useParams();
    const { authenticatedCall } = useAppAuth();
    const { state } = useLocation();
    const { projectState, dispatch } = useContext(ProjectsContext)!;

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="upload-legal-documents-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label={t(`projects.generic_data.back`)} />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <div data-test-id="upload-legal-documents-page" />
                        <LegalDocuments project={projectState.project} showFormAtStart={state && state.showForm} />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
