export const ChevronUpIcon = (props: { size?: number; color?: string }) => {
    const iconSize = props.size ?? 12;
    const stroke = props.color ? props.color : '#17181A';
    return (
        <svg width={iconSize} height={iconSize} viewBox="0 -3 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 2" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12 6L8 2" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};
