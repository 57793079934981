import React, { useContext, useEffect, useMemo, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { ProjectsContext } from '../../contexts/project-context';
import { useAppAuth } from '../../contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import AccountingCard, { QuoteCards } from './AccountingCard';
import QuoteStatusCard from './QuoteStatusCard';
import QuoteForm from './QuoteForm';
import { Project, Quote } from 'src/core/project/domain';
import { Button, Typography } from '@mui/joy';
import { ChevronUpIcon } from 'src/ui/atoms/icons/ChevronUpIcon';
import { ChevronDownIcon } from 'src/ui/atoms/icons/ChevronDownIcon';
import { t } from 'i18next';

export function Quotes({
    project,
    showFormAtStart,
    showFormAndStatus,
}: {
    project: Project;
    showFormAtStart?: boolean;
    showFormAndStatus: boolean;
}) {
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [showForm, setShowForm] = useState<boolean>(false);
    console.log('showForm', showForm);
    useEffect(() => {
        if (project && project.status === 'survey_conducted') {
            setShowForm(true);
        }
        if (project && project.status !== 'survey_conducted') {
            setShowForm(false);
        }
    }, [project]);

    useEffect(() => {
        if (project.status === 'waiting_for_new_quote' && showFormAtStart) {
            setShowForm(true);
        }
    }, [project.status, showFormAtStart]);

    const currentQuotes: Array<Quote> = useMemo(
        () => (project?.quotes ?? []).filter((quote) => quote.status !== 'rejected') || [],
        [project.quotes],
    );
    const rejectedQuotes: Array<Quote> = useMemo(
        () => (project?.quotes ?? []).filter((quote) => quote.status === 'rejected') || [],
        [project.quotes],
    );

    const [showQuotes, setShowQuotes] = useState<boolean>(false);
    const switchOnQuotes = () => {
        setShowQuotes((prevState) => !prevState);
    };

    return (
        <>
            {showFormAndStatus ? (
                <>{<QuoteStatusCard status={project.status} onClick={() => setShowForm(true)} />}</>
            ) : (
                <>
                    {/* <HideQuoteButton switchOnQuotes={switchOnQuotes} /> */}
                    <Button data-test-id="hide-quote-button" variant="outlined" color="grey" onClick={switchOnQuotes}>
                        <Typography level={'title-lg'} color="grey">
                            {showQuotes ? t('quote.hide_quote') : t('quote.show_quote')}
                        </Typography>
                        {showQuotes ? <ChevronUpIcon color="grey" /> : <ChevronDownIcon color="grey" />}
                    </Button>
                </>
            )}

            {(showFormAndStatus || (!showFormAndStatus && showQuotes)) && (
                <>
                    <QuoteCards quotes={currentQuotes} />
                    {rejectedQuotes !== null && rejectedQuotes.length !== 0 && (
                        <AliceCarousel
                            disableButtonsControls={true}
                            responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                            controlsStrategy="responsive"
                            items={rejectedQuotes.map((quote, index) => (
                                <AccountingCard
                                    item={quote}
                                    type="quote"
                                    index={index + 1}
                                    total={project.quotes.length || 0}
                                    key={`quote-${quote.id}`}
                                    small
                                />
                            ))}
                        />
                    )}
                </>
            )}

            {showFormAndStatus && (
                <QuoteForm
                    projectId={project.id}
                    callback={() =>
                        authenticatedCall(async (accessToken) =>
                            dispatch(projectActions.loadOne(accessToken, project.id)),
                        )
                    }
                />
            )}
        </>
    );
}
