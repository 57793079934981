import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import Button from '../Button';
import UploadIcon from 'src/ui/atoms/icons/UploadIcon';

interface FileInputProps {
    onChange: (file: File | null) => void;
    dataTestId?: string;
    file?: File | null;
    setFile?: (file: File | null) => void;
}

export default function FileInput({ onChange, dataTestId, file, setFile }: FileInputProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const onFileUploadClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const { t } = useTranslation();

    return (
        <>
            <input
                type="file"
                onChange={(e) => {
                    const f = e.target.files ? e.target.files[0] : null;
                    if (f && setFile) {
                        setFile(f);
                        onChange(f);
                    }
                    if (e.target) {
                        e.target.value = '';
                    }
                }}
                style={{ display: 'none' }}
                ref={fileInputRef}
                accept=".pdf, .xls, .xlsx, .csv, image/*"
                data-test-id={dataTestId}
            />
            <Button
                color="grey"
                variant={file ? 'solid' : 'soft'}
                onClick={onFileUploadClick}
                sx={{ padding: '32px', height: '152px' }}
            >
                <Stack alignItems="center">
                    {file ? (
                        <Typography sx={{ color: 'white' }} level="body-md">
                            {file.name}
                        </Typography>
                    ) : (
                        <>
                            <UploadIcon />
                            <Typography color="grey" level="body-md">
                                {t('quote.upload')}
                            </Typography>
                        </>
                    )}
                </Stack>
            </Button>
        </>
    );
}
